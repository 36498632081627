export const setLocalStorage = (identifier, payload) => {
    localStorage.setItem(identifier, JSON.stringify(payload));
};

export const clearLocalStorage = (identifier) => {
    localStorage.removeItem(identifier);
};

export const getLocalStorage = (identifier) => {
    const contestantInfo = localStorage.getItem(identifier);
    if (!!contestantInfo) {
        return JSON.parse(contestantInfo);
    } else {
        return null;
    }
};

export const handleCheckEmailValidity = (value) => new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(value);
