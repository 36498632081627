import axios from "axios";

const URL = 'https://aveiegg4li.execute-api.us-east-1.amazonaws.com/prod'
const headers = {
  'Content-Type': 'application/json'
}

export const postReq = async (path, obj, token = false) => {
  try {
    const response = await axios.post(URL + path, obj, {
      headers : headers
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getReq = async (path, token=false) => {
  try {
    const response = await axios.get(URL + path,{
      headers : headers
    });
    return response;
  } catch (error) {
    throw error;
  }
};


export const uploadMedia = async(imageInBase64) => {
  // if theres no image, then pass along
  if(!imageInBase64){
    return ''
  }
  try{
    const response = await postReq('/uploadmedia',{
      file: imageInBase64
    });
    let {data: {link}} = response;
    return link
  }catch(error){
    throw error;
  }
}