import React,{useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Icon, TextArea, Label } from "semantic-ui-react";
import Recaptcha from 'react-recaptcha';

import {postReq} from '../../api'
import { handleCheckEmailValidity } from "../../utils";
import Button from "../button/button";
import Spinner from "../spinner/spinner";



const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    reason:""
}
export default function ContactUs({
    isContact, handleSetIsContact
}) {
    const [open, setOpen] = useState(false);
    const [formState, setFormState] = useState(initialFormState);
    const [errorState, setErrorState] = useState('');
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const [successState, setSuccessState] = useState("");

    const handleFormChange = ({target}) =>{
        setErrorState("");

        const {name, value} = target;
        setFormState({
            ...formState,
            [name]:value
        })
    }

    const submitForm = () =>{
        if(verified){
            if(!formState.email || !handleCheckEmailValidity(formState.email)){
                setErrorState("Please fill in a valid mail!");
                return;
            }
            if(!formState.reason){
                setErrorState("Please tell us how can we help!");
                return;
            }

            setLoading(true);
            postReq('/contactus',{
                firstName: formState.firstName || "N/A",
                lastName: formState.lastName || "N/A",
                phone: formState.phone || "N/A",
                email: formState.email || "N/A",
                query: formState.reason || "N/A"
            })
            .then(res => {
                setFormState(initialFormState);
                setSuccessState("Your query has been submitted. we would get back to you shortly.");
                setTimeout(()=>setSuccessState(""),2000);
            })
            .catch(err=>{
                setErrorState("There was an error sending your query! please try again.", err.message);
                console.log(err);
            })
            .finally(err=>{
                setLoading(false)
            })
            // setLoading(false);
        }
        else{
        }
    }

    return (
        <Modal onClose={handleSetIsContact} onOpen={() => setOpen(true)} open={isContact} className="auth --lg">
            <Modal.Header>Get in touch with us</Modal.Header>

            <Modal.Content>
                <div className="input_group">
                    <div className="auth-input-wrapper --halfed">
                            <Form.Input
                                fluid
                                value={formState.firstName}
                                onChange={handleFormChange}
                                label="First Name"
                                placeholder="Enter your first name"
                                id="firstName"
                                name="firstName"
                                type="firstName"
                            />
                    </div>

                    <div className="auth-input-wrapper --halfed">
                            <Form.Input
                                fluid
                                value={formState.lastName}
                                onChange={handleFormChange}
                                label="Last Name"
                                placeholder="Enter your last name"
                                id="lastName"
                                name="lastName"
                                type="text"
                            />
                    </div>
                </div>


                <div className="input_group">
                    <div className="auth-input-wrapper --halfed">
                            <Form.Input
                                fluid
                                value={formState.email}
                                onChange={handleFormChange}
                                label="Email *"
                                placeholder="Enter your email"
                                id="email"
                                name="email"
                                type="text"
                            />
                    </div>

                    <div className="auth-input-wrapper --halfed">
                            <Form.Input
                                fluid
                                value={formState.phone}
                                onChange={handleFormChange}
                                label="Phone"
                                placeholder="Enter your phone number"
                                id="phone"
                                name="phone"
                                type="text"
                            />
                    </div>
                </div>

                <div className="auth-input-wrapper">
                    <div className="field">
                        <label htmlFor="">
                            How can we help *
                        </label>
                    </div>
                    <TextArea
                        fluid
                        rows={9}
                        placeholder="Tell us how we can be of help"
                        name="reason"
                        onChange={handleFormChange}
                        label="How can we help"
                        value={formState.reason}
                        type="text"
                    />
                </div>
                {errorState && <h4 className="auth-error">{errorState}</h4>}
                {successState && <h4 className="auth-success">{successState}</h4>}
                <div className="captcha_container">
                    <Recaptcha
                        sitekey="6LdP1A8aAAAAAC_jX0RjSSvf0f0s70C7gPeADws3"
                        render="explicit"
                       verifyCallback={(response) => response && setVerified(true)}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>

                <Button
                    variant="orange-border"
                    handleOnClick={submitForm}
                    disabled={loading || !verified}
                >
                    {loading ? <Spinner /> : ""}
                    <span>{loading ? "Submitting Feedback..." : "Submit"}</span>
                </Button>
            </Modal.Actions>

        </Modal>
    )
}
