import React from "react";
import { Modal } from "semantic-ui-react";
import face from "../../assets/about.png";

const AboutModal = ({ isAbout, handleSetIsAbout }) => {
    return (
        <Modal
            onClose={handleSetIsAbout}
            // onOpen={() => setOpen(true)}
            open={isAbout}
            className="auth about-modal"
        >
            <Modal.Content>
                <div style={{ backgroundImage: `url(${face})` }} className="img"></div>
                <div className="details">
                    We are a team of engineers and financial professionals passionate to help small and medium businesses drive revenue growth.
                    <br/>
                    <br/>
                
                    Thinking about your company's financials and which providers, services to use can be confusing. Our job is to make the overall process simpler and easier so that you can focus more on serving your customers.
                </div>
            </Modal.Content>
        </Modal>
    );
};

export default AboutModal;
