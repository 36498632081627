import React from "react";
import PropTypes from "prop-types";

/*
hamburger__rows indicate the three "dashes" that represent the hamburger
hamburger__click-box is used to make sure that the spaces between the "dashes" is also clickable
*/

const Hamburger = ({ handleOnClick, isSidebarOpen }) => {
    return (
        <div className={isSidebarOpen ? "hamburger close" : "hamburger"} onClick={handleOnClick}>
            <div className="hamburger__rows"></div>
            <div className="hamburger__box"></div>
        </div>
    );
};

Hamburger.prototype = {
    handleOnClick: PropTypes.func,
    isSidebarOpen: PropTypes.bool
};

export default Hamburger;
