import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { getReq, postReq } from '../api';
const initialState = {};

// First, create the thunk to fetch all contacts

const fetchBusiness = createAsyncThunk(
	'business/fetchAll',
	async (thunkAPI) => {
        const username = JSON.parse(localStorage.getItem('privateLattice'))['username'];
		const response = await getReq(`/business/${username}`)
		return response.data
	}
)

const updateBusiness = createAsyncThunk(
	'business/updateOne',
	async (payload, thunkAPI) => {
		const response = await postReq(`/business/update/`, payload)
		return response.data
	}
)

const addBusinessForm = createAsyncThunk(
	'business/addOne',
	async (payload, thunkAPI) => {
		const response = await postReq(`/business/add/`, payload)
		return response.data
	}
)

const deleteBusinessForm = createAsyncThunk(
    'business/deleteOne',
	async (payload, thunkAPI) => {
        const username = JSON.parse(localStorage.getItem('privateLattice'))['username'];
		const response = await getReq(`/business/delete/${payload}`)
		return response.data
	}
)

const mySlice = createSlice({
    name: "businessForm",
    initialState: initialState,
    extraReducers: {
        [fetchBusiness.fulfilled]: (state, action) => {
			return action.payload
		},
    }
});


export {
    fetchBusiness, addBusinessForm,
    updateBusiness, deleteBusinessForm
}
export default mySlice.reducer;
