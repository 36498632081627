import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { getReq, postReq } from '../api';
const initialState = [];

// First, create the thunk to fetch all contacts

const fetchAllContacts = createAsyncThunk(
	'contacts/fetchAll',
	async (thunkAPI) => {
        const username = JSON.parse(localStorage.getItem('privateLattice'))['username'];
		const response = await getReq(`/contacts/${username}`)
		return response.data
	}
)

const updateOneContact = createAsyncThunk(
	'contacts/updateOne',
	async (payload, thunkAPI) => {
		const response = await postReq(`/contacts/update/`, payload)
		return response.data
	}
)

const addContactForm = createAsyncThunk(
	'contacts/addOne',
	async (payload, thunkAPI) => {
		const response = await postReq(`/contacts/add/`, payload)
		return response.data
	}
)

const deleteContactForm = createAsyncThunk(
    'contacts/deleteOne',
	async (payload, thunkAPI) => {
        const username = JSON.parse(localStorage.getItem('privateLattice'))['username'];
		const response = await getReq(`/contacts/delete/${payload}`)
		return response.data
	}
)

const mySlice = createSlice({
    name: "contactForms",
    initialState: initialState,
    reducers: {
        requestAddNewContactForm: (state, action) => {
            const { name, title, phone, email, address, industry, businessType, id } = action.payload;
            state = [...state, action.payload];
            return state;
        },
        requestUpdateContactForm: (state, action) => {
            const newState = JSON.parse(JSON.stringify(state));
            const _newState = newState.map((form) => {
                if(form.id === action.payload.id){
                    return action.payload
                }
                return form
            });
            const finalState = [..._newState];
            return finalState;
        },
        requestDeleteContactForm: (state, action) => {
            const newState = state.filter((form) => {
                return form.id !== action.payload;
            });
            return newState;
        }
    },
    extraReducers: {
        [fetchAllContacts.fulfilled]: (state, action) => {
            const { contacts } = action.payload;
            return contacts
		},
    }
});

const {
    requestAddNewContactForm, requestUpdateContactForm, requestDeleteContactForm,
} = mySlice.actions;

export {
    requestAddNewContactForm, requestUpdateContactForm,
    fetchAllContacts,  requestDeleteContactForm, addContactForm,
    updateOneContact, deleteContactForm
}
export default mySlice.reducer;
