import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Auth } from "aws-amplify";

import { setUserData, setFacebookLoading, setIsAbout } from "../../redux/authSlice";
import { setIsSignUp, setIsLogin, requestLogout, setIsContact } from "../../redux/authSlice";

import { setLocalStorage, handleCheckEmailValidity } from "../../utils";
import logo from "../../assets/logo.svg";
import arrow from "../../assets/arrow.svg";

import { NavLink, useLocation } from "react-router-dom";
import { clearLocalStorage } from "../../utils";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Navbar = () => {
    const dispatch = useDispatch();
    const { username } = useSelector((state) => state.auth);

    // if we are coming from a facebook i.e you have a 'code' query parameter,
    // open up the login window
    if (useQuery().get("code") && !username) {
        dispatch(setIsLogin(true));
        dispatch(setFacebookLoading(true));
    }
    //  if we are on the homepage hide the rest content
    if (username) {
        dispatch(setIsLogin(false));
        dispatch(setFacebookLoading(false));
    }

    const handleShowSignupModal = useCallback(() => {
        dispatch(setIsSignUp(true));
    }, [dispatch]);

    const handleShowLoginModal = useCallback(() => {
        dispatch(setIsLogin(true));
    }, [dispatch]);

    const handleShowAboutModal = useCallback(() => {
        dispatch(setIsAbout(true));
    }, [dispatch]);

    const handleShowContactModal = useCallback(() => {
        dispatch(setIsContact(true))
    }, [dispatch]);

    const handleLogout = () => {
        clearLocalStorage("privateLattice");
        dispatch(requestLogout());
        Auth.signOut();
    };

    useEffect(() => {
        // get the currently authenticated user
        // and do something if they exist
        // if not, close the login loader and stop spinning
        Auth.currentAuthenticatedUser()
            .then((user) => {
                const { signInUserSession, attributes, username } = user;
                const { idToken } = signInUserSession;
                const { jwtToken, payload } = idToken;
                const info = {
                    username,
                    attributes,
                    token: jwtToken,
                    tokenInfo: payload
                };
                setLocalStorage("privateLattice", info);
                dispatch(setUserData(info));
                dispatch(setIsLogin(false));
                dispatch(setFacebookLoading(false));
            })
            .finally(() => {
                dispatch(setIsLogin(false));
                dispatch(setFacebookLoading(false));
            });
    }, [dispatch]);

    return (
        // Fixed the navbar if we are logged out
        <div className="nav" style={{ position: username ? "relative" : "fixed" }}>
            <div className="nav__logo">
                <NavLink exact to="/">
                    <img src={logo} alt="logo" />
                </NavLink>
            </div>
            {username ? (
                <div className="nav__links">
                    <NavLink exact to="/">
                        Home
                    </NavLink>
                    <NavLink exact to="/my-recommendations">
                        My Recommendations
                        <img src={arrow} alt="arrow" />
                    </NavLink>
                    {/* <NavLink exact to="/accounts">
                        Accounts
                    </NavLink> */}
                    <NavLink exact to="resources">
                        My News
                        <img src={arrow} alt="arrow" />
                    </NavLink>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            ) : (
                <div className="nav__links">
                    <button onClick={handleShowAboutModal}>About</button>
                    <button onClick={handleShowSignupModal}>Sign Up</button>
                    <button onClick={handleShowLoginModal}>Login</button>
                    <button onClick={handleShowContactModal}>Contact Us</button>
                </div>
            )}
        </div>
    );
};

export default Navbar;
