import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    username: "",
    attributes: null,
    token: null,
    tokenInfo: null,
    isSignUp: false,
    isLogin: false,
    isAbout: false,
    isFacebookLoading: false,
    isContact: false
};

const mySlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setUserData: (state, action) => {
            const { username, attributes, token, tokenInfo } = action.payload;
            state.username = username;
            state.attributes = attributes;
            state.token = token;
            state.tokenInfo = tokenInfo;
            return state;
        },
        setIsContact: (state, action) => {
            state.isContact = action.payload;
            return state;
        },
        setIsSignUp: (state, action) => {
            state.isSignUp = action.payload;
            return state;
        },
        setIsLogin: (state, action) => {
            state.isLogin = action.payload;
            return state;
        },
        setIsAbout: (state, action) => {
            state.isAbout = action.payload;
            return state;
        },
        requestLogout: (state) => {
            state.username = "";
            state.attributes = null;
            state.token = null;
            state.tokenInfo = null;
            state.isLogin = false;
            state.isSignUp = false;
            return state;
        },
        setFacebookLoading: (state, action) => {
            state.isFacebookLoading = action.payload;
        }
    }
});

export const { setUserData, setIsSignUp, setIsLogin, setIsAbout, requestLogout, setFacebookLoading, setIsContact } = mySlice.actions;
export default mySlice.reducer;
