import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/logo.svg";
import arrow from "../../assets/arrow.svg";

import { NavLink } from "react-router-dom";
import { setIsSignUp, setIsLogin, requestLogout, setIsAbout, setIsContact } from "../../redux/authSlice";
import { clearLocalStorage } from "../../utils";

const Sidebar = ({ isSidebarOpen, handleOnClick }) => {
    const dispatch = useDispatch();

    const { username } = useSelector((state) => state.auth);

    const handleShowSignupModal = useCallback(() => {
        dispatch(setIsSignUp(true));
    }, [dispatch]);

    const handleShowLoginModal = useCallback(() => {
        dispatch(setIsLogin(true));
    }, [dispatch]);

    const handleShowAboutModal = useCallback(() => {
        dispatch(setIsAbout(true));
    }, [dispatch]);

    const handleShowContactModal = useCallback(() => {
        dispatch(setIsContact(true))
    }, [dispatch]);

    const handleLogout = () => {
        clearLocalStorage("privateLattice");
        dispatch(requestLogout());
    };

    return (
        <div className={isSidebarOpen ? "show-sidebar sidebar" : "sidebar"} onClick={handleOnClick}>
            <div className="sidebar__logo">
                <NavLink exact to="/">
                    <img src={logo} alt="logo" />
                </NavLink>
            </div>
            {username ? (
                <div className="sidebar__links">
                    <NavLink exact to="/">
                        Home
                    </NavLink>
                    <NavLink exact to="/my-recommendations">
                        My Recommendations
                        <img src={arrow} alt="arrow" />
                    </NavLink>
                    {/* <NavLink exact to="/accounts">
                        Accounts
                    </NavLink> */}
                    <NavLink exact to="resources">
                        My News
                        <img src={arrow} alt="arrow" />
                    </NavLink>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            ) : (
                <div className="sidebar__links">
                    <button onClick={handleShowAboutModal}>About</button>
                    <button onClick={handleShowSignupModal}>Sign Up</button>
                    <button onClick={handleShowLoginModal}>Login</button>
                    <button onClick={handleShowContactModal}>Contact Us</button>
                </div>
            )}
        </div>
    );
};

export default Sidebar;
