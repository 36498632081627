import React from "react";
import PropTypes from "prop-types";

const Button = ({ children, variant, disabled = false, handleOnClick, type }) => {
    const renderButton = () => {
        switch (variant) {
            case "white-border":
                return (
                    <button className="btn white-border" type={type} disabled={disabled} onClick={handleOnClick}>
                        {children}
                    </button>
                );
            case "orange-border":
                return (
                    <button className="btn orange-border" type={type} disabled={disabled} onClick={handleOnClick}>
                        {children}
                    </button>
                );
            default:
                return (
                    <button className="btn" disabled={disabled} type={type} onClick={handleOnClick}>
                        {children}
                    </button>
                );
        }
    };
    return renderButton();
};

Button.propTypes = {
    children: PropTypes.any,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    handleOnClick: PropTypes.func
};

export default Button;
