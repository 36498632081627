import React, { Suspense, lazy, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Message } from "semantic-ui-react";

import Navbar from "./components/navbar/navbar";
import Sidebar from "./components/sidebar/sidebar";
import Backdrop from "./components/backdrop/backdrop";
import Hamburger from "./components/hamburger/hamburger";
import LoginModal from "./components/loginModal/loginModal";
import SignupModal from "./components/signupModal/signupModal";
import AboutModal from "./components/aboutModal/aboutModal";
import ContactModal from "./components/contactUs/contactUs";
import LoadingScreen from "./components/loadingScreen/loadingScreen";

import { setIsLogin, setIsSignUp, setUserData, requestLogout, setIsAbout, setIsContact } from "./redux/authSlice";
import { clearLocalStorage, getLocalStorage } from "./utils";

//----------------------------------------------------------------
//Code split in order to reduce bundle size
//----------------------------------------------------------------
const Home = lazy(() => import("./pages/home" /* webpackChunkName: "Home" */));
const LogoutHome = lazy(() => import("./pages/logoutHome" /* webpackChunkName: "LogoutHome" */));
// const Accounts = lazy(() => import("./pages/accounts" /* webpackChunkName: "Accounts" */));
const Resources = lazy(() => import("./pages/resources" /* webpackChunkName: "Resources" */));
const MarketPlace = lazy(() => import("./pages/marketPlace" /* webpackChunkName: "MarketPlace" */));
const AdminDashboard = lazy(() => import("./pages/adminDashboard" /* webpackChunkName: "AdminDashboard" */));

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { username } = useSelector((state) => state.auth);

    return (
        <Route
            {...rest}
            render={(props) => (username ? <Component {...props} /> : <Redirect to={{ pathname: "/" }} />)}
        />
    );
};

const App = () => {
    const dispatch = useDispatch();

    const { isSignUp } = useSelector((state) => state.auth);
    const { isLogin } = useSelector((state) => state.auth);
    const { isAbout } = useSelector((state) => state.auth);
    const { username } = useSelector((state) => state.auth);
    const { isContact } = useSelector((state) => state.auth);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const errorMessage = useSelector((state) => state.error.errorMessage);
    const successMessage = useSelector((state) => state.error.successMessage);

    const handleShowSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleSetIsLogin = () => {
        dispatch(setIsLogin(false));
    };

    const handleSetIsSignup = () => {
        dispatch(setIsSignUp(false));
    };

    const handleSetIsAbout = () => {
        dispatch(setIsAbout(false));
    };

    const handleSetIsContact = () => {
        dispatch(setIsContact(false))
    };

    const renderRoutes = () => {
        if (username) {
            return (
                <>
                    <ProtectedRoute exact path="/" component={Home} />
                    <ProtectedRoute exact path="/my-recommendations" component={MarketPlace} />
                    {/* <ProtectedRoute exact path="/accounts" component={Accounts} /> */}
                    <ProtectedRoute exact path="/resources" component={Resources} />
                </>
            );
        } else {
            return (
                <>
                    <Route exact path="/" component={LogoutHome} />
                    <Route exact path="/signup" component={LogoutHome} />
                    <Route exact path="/admin" component={AdminDashboard} />
                </>
            );
        }
    };

    return (
        <>
            <BrowserRouter>
                <Navbar />
                <Sidebar isSidebarOpen={isSidebarOpen} handleOnClick={() => setIsSidebarOpen(false)} />
                <Backdrop showBackdrop={isSidebarOpen} />
                <Hamburger handleOnClick={handleShowSidebar} isSidebarOpen={isSidebarOpen} />

                <Suspense fallback={<LoadingScreen />}>
                    <Switch>{renderRoutes()}</Switch>
                </Suspense>
                <LoginModal isLogin={isLogin} handleSetIsLogin={handleSetIsLogin} />
                <SignupModal isSignup={isSignUp} handleSetIsSignup={handleSetIsSignup} />
                <AboutModal isAbout={isAbout} handleSetIsAbout={handleSetIsAbout} />
                <ContactModal isContact={isContact} handleSetIsContact={handleSetIsContact} />
            </BrowserRouter>
            {errorMessage && errorMessage.length > 0 && (
                <Message id="errorMessage" icon="cancel" header="There was an error" content={errorMessage} wide />
            )}
            {successMessage && successMessage.length > 0 && (
                <Message
                    id="successMessage"
                    icon="check"
                    header="There was a successful action"
                    content={successMessage}
                    wide
                />
            )}
        </>
    );
};

export default App;
