import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { getReq, postReq } from '../api';
const initialState = {};

// First, create the thunk to fetch all contacts

const fetchFinancials = createAsyncThunk(
	'financials/fetchAll',
	async (thunkAPI) => {
        const username = JSON.parse(localStorage.getItem('privateLattice'))['username'];
		const response = await getReq(`/financials/${username}`)
		return response.data
	}
)

const updateFinancial = createAsyncThunk(
	'financials/updateOne',
	async (payload, thunkAPI) => {
		const response = await postReq(`/financials/update/`, payload)
		return response.data
	}
)

const addFinancialForm = createAsyncThunk(
	'financials/addOne',
	async (payload, thunkAPI) => {
		const response = await postReq(`/financials/add/`, payload)
		return response.data
	}
)

const deleteFinancialForm = createAsyncThunk(
    'financials/deleteOne',
	async (payload, thunkAPI) => {
        const username = JSON.parse(localStorage.getItem('privateLattice'))['username'];
		const response = await getReq(`/financials/delete/${payload}`)
		return response.data
	}
)

const mySlice = createSlice({
    name: "financialForm",
    initialState: initialState,
    extraReducers: {
        [fetchFinancials.fulfilled]: (state, action) => {
            const { financials } = action.payload;
            return financials
		},
    }
});


export {
    fetchFinancials, addFinancialForm,
    updateFinancial, deleteFinancialForm
}
export default mySlice.reducer;
