import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import contactFormsReducer from "./contactFormsSlice";
import financialFormsReducer from "./financialFormSlice";
import aboutBusinessFormsReducer from "./aboutBusinessFormSlice";
import errorReducer from "./errorSlice";


const rootReducer = combineReducers({
    auth: authReducer,
    contactForms: contactFormsReducer,
    financialForm: financialFormsReducer,
    aboutBusiness: aboutBusinessFormsReducer,
    error: errorReducer
});

const store = configureStore({
    reducer: rootReducer
});

export default store;
