/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:96de45a9-a4a6-4a3a-bd76-02326b8616d5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QYOZRkQ9S",
    "aws_user_pools_web_client_id": "1hqnspo4em29voanck9h59r7hd",
    "oauth": {
        "domain": "privatelatticea0c6deae-a0c6deae-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.privatelattice.com/",
        "redirectSignOut": "https://www.privatelattice.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
