import React, { useState, useEffect } from "react";
import { Modal, Form, Icon } from "semantic-ui-react";
import { Auth } from "aws-amplify";

import Button from "../button/button";
import Spinner from "../spinner/spinner";
import { handleCheckEmailValidity } from "../../utils";

const SignupModal = ({ isSignup, handleSetIsSignup }) => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [authErr, setAuthErr] = useState("");

    const [email, setEmail] = useState({
        value: "",
        hasError: false,
        errorMessage: ""
    });
    const [password, setPassword] = useState({
        value: "",
        hasError: false,
        errorMessage: ""
    });
    const [confirmPassword, setConfirmPassword] = useState({
        value: "",
        hasError: false,
        errorMessage: ""
    });

    // State for Hiding/Showing Password Text
    const [showPasswordText, setShowPasswordText] = useState(false)

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        if (name === "email") {
            setEmail({ ...email, value });
            return;
        }

        if (name === "password") {
            setPassword({ ...password, value });
            return;
        }

        if (name === "confirmPassword") {
            setConfirmPassword({ ...confirmPassword, value });
            return;
        }
    };

    const handleValidateFields = () => {
        let emailErr;
        let passwordErr;
        let confirmPasswordErr;

        //-------------------------------------------------------------------------------
        //Email

        if (!handleCheckEmailValidity(email.value)) {
            emailErr = true;
            setEmail({ ...email, hasError: true, errorMessage: "Please enter a valid email" });
        } else {
            emailErr = false;
            setEmail({ ...email, hasError: false, errorMessage: "" });
        }

        //-------------------------------------------------------------------------------
        //Password
        if (password.value.length <= 3) {
            passwordErr = true;
            setPassword({ ...password, hasError: true, errorMessage: "Password should be more than 3 characters" });
        } else {
            passwordErr = false;
            setPassword({ ...password, hasError: false, errorMessage: "" });
        }

        //-------------------------------------------------------------------------------
        //Confirm Password
        if (confirmPassword.value !== password.value) {
            confirmPasswordErr = true;
            setConfirmPassword({ ...confirmPassword, hasError: true, errorMessage: "Must match password field" });
        } else {
            confirmPasswordErr = false;
            setConfirmPassword({ ...confirmPassword, hasError: false, errorMessage: "" });
        }

        //-------------------------------------------------------------------------------

        //-------------------------------------------------------------------------------
        //Return statement
        //-------------------------------------------------------------------------------
        if (
            emailErr ||
            passwordErr ||
            confirmPasswordErr
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleOnSubmit = async () => {
        const errExists = handleValidateFields();
        if (errExists) {
            return;
        }
        setIsLoading(true);
        setIsDisabled(true);

        let signUpResponse;

        try {
            signUpResponse = await Auth.signUp({
                username: email.value,
                password: password.value,
            });

            if (signUpResponse) {
                //Reset all state values
                setIsLoading(false);
                setIsDisabled(false);
                setAuthErr("");
                setEmail({ value: "", hasError: false, errorMessage: "" });
                setPassword({ value: "", hasError: false, errorMessage: "" });
                setConfirmPassword({ value: "", hasError: false, errorMessage: "" });
                setShowConfirmation(true);
            }
        } catch (err) {
            console.log(err);
            setIsLoading(false);
            setIsDisabled(false);
            setAuthErr(err.message);
        }
    };

    const handleCloseModal = () => {
        //Reset all state values
        setIsLoading(false);
        setIsDisabled(false);
        setAuthErr("");
        setEmail({ value: "", hasError: false, errorMessage: "" });
        setPassword({ value: "", hasError: false, errorMessage: "" });
        setConfirmPassword({ value: "", hasError: false, errorMessage: "" });
        setShowConfirmation(false);
        handleSetIsSignup();
    };

    const FORM_FIELDS = [
        {
            state: email,
            label: "Email",
            placeholder: "Email",
            id: "email",
            name: "email",
            type: "email"
        },
        {
            state: password,
            label: "Password ",
            placeholder: "Password",
            id: "password",
            name: "password",
            type: "password",
            instruction: "(must include symbol, uppercase, lowercase and special character)"
        },
        {
            state: confirmPassword,
            label: "Confirm Password",
            placeholder: "Confirm Password",
            id: "confirmPassword",
            name: "confirmPassword",
            type: "password"
        }
    ];

    if (showConfirmation) {
        return (
            <Modal onClose={handleCloseModal} onOpen={() => setOpen(true)} open={isSignup} className="auth">
                <Modal.Header>Almost Done</Modal.Header>
                <Modal.Content>
                    <p style={{ textAlign: "left" }}>
                        Please verify the confirmation email sent to your mailbox in order to login succesfully
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button variant="orange-border" handleOnClick={handleCloseModal}>
                        Proceed
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    return (
        <Modal onClose={handleCloseModal} onOpen={() => setOpen(true)} open={isSignup} className="auth">
            <Modal.Header>Sign Up</Modal.Header>
            <Modal.Content>
                {FORM_FIELDS.map((field, i) => {
                    const { state, label, placeholder, id, name, type, instruction } = field;
                    const { value, hasError, errorMessage } = state;
                    return (
                        <div className="auth-input-wrapper" key={i}>
                            {name === "password" ? <h5 className="instruction">{instruction}</h5> : null}
                            <Form.Input
                                error={hasError ? { content: errorMessage } : false}
                                fluid
                                value={value}
                                onChange={handleOnChange}
                                label={label}
                                placeholder={placeholder}
                                id={id}
                                name={name}
                                type={name.includes("assword") && showPasswordText ? "text" : type}
                                icon={name.includes("assword") ? <Icon name={showPasswordText ? "eye slash" : "eye"} link onClick={() => { setShowPasswordText(!showPasswordText) }} /> : null }
                            />
                        </div>
                    );
                })}
                {authErr && <h4 className="auth-error">{authErr}</h4>}
            </Modal.Content>
            <Modal.Actions>
                <Button variant="orange-border" handleOnClick={handleOnSubmit} disabled={isDisabled}>
                    {isLoading ? <Spinner /> : ""}
                    <span>{isLoading ? "Loading..." : "Sign Up"}</span>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SignupModal;
