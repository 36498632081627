import React from "react";

import logo from "../../assets/logo.svg";

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <img src={logo} alt="logo" />
        </div>
    );
};

export default LoadingScreen;
