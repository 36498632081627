import React from "react";
import { useDispatch } from "react-redux";
import App from "./App";
import { getLocalStorage } from "./utils";
import { setUserData, requestLogout } from "./redux/authSlice";

const Root = () => {
    const dispatch = useDispatch();

    const userData = getLocalStorage("privateLattice");

    if (userData) {
        const { tokenInfo } = userData;
        const { exp } = tokenInfo;
        const expirationTime = exp * 1000;
        if (new Date().getTime() > expirationTime) {
            // dispatch(requestLogoutAdmin());
            dispatch(requestLogout());
        } else {
            dispatch(setUserData(userData));
        }
    }
    return <App />;
};

export default Root;
