import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Icon } from "semantic-ui-react";

import { Auth } from "aws-amplify";
import { withOAuth } from "aws-amplify-react";
import jwtDecode from "jwt-decode";

import Button from "../button/button";
import Spinner from "../spinner/spinner";
import { setLocalStorage, handleCheckEmailValidity } from "../../utils";
import { setUserData, setFacebookLoading } from "../../redux/authSlice";

const LoginModal = ({ isLogin, handleSetIsLogin }) => {
    const { isFacebookLoading } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showVerificationCode, setShowVerificationCode] = useState(false);
    const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
    const [authSuccess, setAuthSuccess] = useState("");
    const [authErr, setAuthErr] = useState("");
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [forgotPassEmail, setForgotPassEmail] = useState({
        value: "",
        hasError: false,
        errorMessage: ""
    });
    const [resetPassword, setResetPassword] = useState({
        newPassword: "",
        verificationCode: "",
        loading: false,
        errorMessage: ""
    });
    const [email, setEmail] = useState({
        value: "",
        hasError: false,
        errorMessage: ""
    });
    const [password, setPassword] = useState({
        value: "",
        hasError: false,
        errorMessage: ""
    });
    // State for Hiding/Showing Password Text
    const [showPasswordText, setShowPasswordText] = useState(false)

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        if (name === "email") {
            setEmail({ ...email, value });
            return;
        }

        if (name === "password") {
            setPassword({ ...password, value });
            return;
        }

        if (name === "forgotPassEmail") {
            setForgotPassEmail({ ...password, value });
            return;
        }
        if (['newPassword','verificationCode'].includes(name)){
            setResetPassword({...resetPassword, [name]:value})
        }
    };
    const handleValidateFields = () => {
        let emailError;
        let passwordError;

        if (!handleCheckEmailValidity(email.value)) {
            emailError = true;
            setEmail({ ...email, hasError: true, errorMessage: "Please enter a valid email" });
        }

        if (password.value.length <= 3) {
            passwordError = true;
            setPassword({ ...password, hasError: true, errorMessage: "Password should be more than 3 characters" });
        }

        if (emailError || passwordError) {
            return true;
        } else {
            return false;
        }
    };
    const handleFacebookSignin = () => {
        Auth.federatedSignIn({ provider: "Facebook" });
        dispatch(setFacebookLoading(true));
    };
    const handleOnSubmit = async () => {
        const errExists = handleValidateFields();
        if (errExists) {
            return;
        }
        setIsLoading(true);
        setIsDisabled(true);
        setAuthErr("");

        let loginResponse;

        try {
            loginResponse = await Auth.signIn(email.value, password.value);

            if (loginResponse) {
                const { signInUserSession, attributes, username } = loginResponse;
                const { idToken } = signInUserSession;
                const { jwtToken, payload } = idToken;
                const info = {
                    username,
                    attributes,
                    token: jwtToken,
                    tokenInfo: payload
                };

                setLocalStorage("privateLattice", info);
                dispatch(setUserData(info));

                //Reset all state values
                setIsLoading(false);
                setIsDisabled(false);
                setAuthErr("");
                setEmail({ value: "", hasError: false, errorMessage: "" });
                setPassword({ value: "", hasError: false, errorMessage: "" });

                handleSetIsLogin();
            }
        } catch (err) {
            setIsLoading(false);
            setIsDisabled(false);
            setAuthErr(err.message);
        }
    };

    const handleCloseModal = () => {
        //Reset all state values
        setIsLoading(false);
        setIsDisabled(false);
        setForgotPasswordLoading(false);
        setAuthErr("");
        setEmail({ value: "", hasError: false, errorMessage: "" });
        setPassword({ value: "", hasError: false, errorMessage: "" });
        setShowForgotPassword(false);
        setForgotPassEmail({ value: "", hasError: false, errorMessage: "" });
        handleSetIsLogin();
    };

    const handleShowForgotPass = useCallback(() => {
        setShowForgotPassword(true);
    }, [showForgotPassword]);

    const handleForgotPassword = () => {
        setForgotPasswordLoading(true);
        Auth.forgotPassword(forgotPassEmail.value)
        .then(data => {
            setShowForgotPassword(false);
            setShowVerificationCode(true);
        })
        .catch(err => {
            setForgotPassEmail({...forgotPassEmail, errorMessage:err.message})
        })
        .finally(()=>{
            setForgotPasswordLoading(false);
        })
    };

    const handleResetPassword = () => {
        setResetPassword({...resetPassword, loading:true})
        Auth.forgotPasswordSubmit(forgotPassEmail.value, resetPassword.verificationCode, resetPassword.newPassword)
        .then(data => {
            setShowVerificationCode(false);
            setResetPassword({newPassword: "",verificationCode: "",errorMessage: "", loading:false});
            // prefill the mail and password from the updated values  
            setEmail({...email, value:forgotPassEmail.value});
            setPassword({...password, value:resetPassword.newPassword});
            setAuthSuccess("Password Reset Succesfull, Proceed to login");
            setAuthErr('');
        })
        .catch((err) => {
            setResetPassword({...resetPassword, errorMessage:err.message, loading:false});
            // console.log(resetPassword)
        })

    }

    const FORM_FIELDS = [
        {
            state: email,
            label: "Email",
            placeholder: "Email",
            id: "email",
            name: "email",
            type: "text"
        },
        {
            state: password,
            label: "Password",
            placeholder: "Password",
            id: "password",
            name: "password",
            type: "password"
        }
    ];

    const renderModal = () => {
        if (showForgotPassword) {
            return (
                <>
                    <Modal.Header>Enter your email address</Modal.Header>
                    <Modal.Content>
                        <div className="auth-input-wrapper">
                            <Form.Input
                                fluid
                                value={forgotPassEmail.value}
                                onChange={handleOnChange}
                                label="Email"
                                placeholder="Email address"
                                id="forgotPassEmail"
                                name="forgotPassEmail"
                                type="email"
                            />
                        </div>
                        {forgotPassEmail.errorMessage && <h4 className="auth-error">{forgotPassEmail.errorMessage}</h4>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button variant="orange-border" handleOnClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="orange-border" handleOnClick={handleForgotPassword} disabled={forgotPasswordLoading}>
                            {forgotPasswordLoading ? <Spinner /> : ""}
                            <span>{forgotPasswordLoading ? "Sending Code..." : "Send Code"}</span>
                        </Button>
                    </Modal.Actions>
                </>
            );
        }
        else if(showVerificationCode) {
            return (
                <>
                    <Modal.Header>Verify your account</Modal.Header>
                    <Modal.Content>
                        <div className="auth-input-wrapper">
                            <Form.Input
                                fluid
                                value={resetPassword.verificationCode}
                                onChange={handleOnChange}
                                label="Verification Code"
                                placeholder="Enter the verification code sent to your mail"
                                id="verificationCode"
                                name="verificationCode"
                                type="text"
                            />
                        </div>
                        <div className="auth-input-wrapper">
                            <Form.Input
                                fluid
                                value={resetPassword.newPassword}
                                onChange={handleOnChange}
                                label="New password"
                                placeholder="Enter your new password"
                                id="newPassword"
                                name="newPassword"
                                type={showPasswordText ? "text" : "password"}
                                icon={<Icon name={showPasswordText ? "eye slash" : "eye"} link onClick={() => { setShowPasswordText(!showPasswordText) }} />}
                            />
                        </div>
                        {resetPassword.errorMessage && <h4 className="auth-error">{resetPassword.errorMessage}</h4>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button variant="orange-border" handleOnClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="orange-border" handleOnClick={handleResetPassword} disabled={forgotPasswordLoading}>
                            {resetPassword.loading ? <Spinner /> : ""}
                            <span>{resetPassword.loading ? "Confirming Code..." : "Confirm Code"}</span>
                        </Button>
                    </Modal.Actions>
                </>
            );
        } else {
            return (
                <>
                    <Modal.Header>Login</Modal.Header>
                    <Modal.Content>
                        {FORM_FIELDS.map((field, i) => {
                            const { state, label, placeholder, id, name, type } = field;
                            const { value, hasError, errorMessage } = state;
                            return (
                                <div className="auth-input-wrapper" key={i}>
                                    <Form.Input
                                        error={errorMessage ? { content: errorMessage } : false}
                                        fluid
                                        value={value}
                                        onChange={handleOnChange}
                                        label={label}
                                        placeholder={placeholder}
                                        id={id}
                                        name={name}
                                        type={name === "password" && showPasswordText ? "text" : type}
                                        icon={name === "password" ? <Icon name={showPasswordText ? "eye slash" : "eye"} link onClick={() => { setShowPasswordText(!showPasswordText) }} /> : null }
                                    />
                                </div>
                            );
                        })}
                        {authErr && <h4 className="auth-error">{authErr}</h4>}
                        {authSuccess && <h4 className="auth-success">{authSuccess}</h4>}
                        <button onClick={handleShowForgotPass} className="forgot-password">
                            Forgot password?
                        </button>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button variant="orange-border" handleOnClick={handleOnSubmit} disabled={isDisabled}>
                            {isLoading ? <Spinner /> : ""}
                            <span>{isLoading ? "Loading..." : "Login"}</span>
                        </Button>

                        <Button
                            variant="orange-border"
                            handleOnClick={handleFacebookSignin}
                            disabled={isFacebookLoading}
                        >
                            {isFacebookLoading ? <Spinner /> : ""}
                            <span>{isFacebookLoading ? "Loading..." : "Login with Facebook"}</span>
                        </Button>
                    </Modal.Actions>
                </>
            );
        }
    };

    return (
        <Modal onClose={handleCloseModal} onOpen={() => setOpen(true)} open={isLogin} className="auth">
            {renderModal()}
        </Modal>
    );
};

export default withOAuth(LoginModal);
