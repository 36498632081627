import React from "react";

const Backdrop = ({ showBackdrop }) => {
    if (showBackdrop) {
        return <div className="backdrop"></div>;
    }
    return null;
};

export default Backdrop;
